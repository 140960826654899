/*=================================================================*/
/*                      BASE                              
/*=================================================================*/
body {
  color: #888;
  background-color: #ffffff;
  font-family: $fontbody;
  font-size: 14px;
  line-height: 1.7;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-dark {
  color: #292E34 !important;
}

.relative {
  position: relative;
}

.color-white {
  color: #fff;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }
}

.background-blue {
  background: $colorblue;
}

/*=================================================================*/
/*                      TYPOGRAPHY                              
/*=================================================================*/

.justified-text {
  text-align: justify;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #292E34;
  font-family: $fontstack;
  font-weight: 700;
  margin: 20px 0;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

a {
  color: $colordefault;
  outline: 0;
  @include transition(0.3s);
  &:hover {
    color: $colororange;
    text-decoration: none;
  }
  &:focus {
    outline: 0;
  }
}

blockquote {
  padding: 20px 20px;
  margin: 0 0 20px;
  font-size: 16px;
  background: #f7f7f7;
  border-radius: 10px;
}

blockquote p {
  line-height: 1.6;
}

/* === Pre === */
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 0;
}

::selection {
  color: #fff;
  background: $colororange;
}

::-moz-selection {
  color: #fff;
  background: $colororange;
}

.z-1 {
  z-index: 1;
}

.row {
  margin-right: -10px;
  margin-left: -10px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 10px;
  padding-left: 10px;
}

// Spacing
[data-height="97"] {
  height: 97px;
}
[data-height="80"] {
  height: 80px;
}
[data-height="50"] {
  height: 50px;
}
[data-height="20"] {
  height: 20px;
}
[data-height="10"] {
  height: 10px;
}
.-mt-50 {
  margin-top: -50px;
}
.mt-50 {
  margin-top: 50px;
}
.-mt-20 {
  margin-top: -20px;
}
.mt-20 {
  margin-top: 20px;
}
