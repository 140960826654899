/*
Template Name: Hanan Semab
Version: 1.0.0
Author: PxlSolutions
Author URI: http://themeforest.net/user/pxlsolutions
Description: Personal Portfolio & Resume HTML Template

[TABLE OF CONTENTS]

01. Base
02. Typography
03. Header
04. Skills
05. Portfolio
06. Clients
07. Blog
08. Helper
09. Buttons
10. Preloader
11. Bootstrap Elements
12. Theme Elements
13. Slick Settings
14. Responsive Settings

*/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i&display=swap&subset=cyrillic-ext");
@import "./sass/mixins";
@import "./sass/base";
@import "./sass/header";
@import "./sass/main";
@import "./sass/buttons";
@import "./sass/elements";
@import "./sass/slick";
@import "./sass/responsive";

// Demo Css - Please remove when it's unnecessary.

.demosection {
  border-radius: 0;
  .overlay {
    border-radius: 0;
  }
  &.herosection {
    padding: 100px 0;
  }
}
.demo-content {
  position: relative;
  z-index: 99;
  text-align: center;
  color: #fff;

  h1,
  h4 {
    color: #fff;
  }

  h1 {
    margin-bottom: 10px;
  }

  h4 {
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
    padding: 30px 0;
  }
}

.demoitems {
  padding: 100px 0;
  text-align: center;
  h2 {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.demoitem {
  text-align: center;

  img {
    border: 1px solid #ddd;
    border-radius: 15px;
    overflow: hidden;
  }
  h4 {
    margin-bottom: 0;
  }
}

.mb-50 {
  margin-bottom: 50px;
}
