$fontstack: 'Poppins', sans-serif;
$fontbody: 'Open Sans', sans-serif;
$colordefault: #262626;
$colorblue: #042940;
$colororange: #9FC131;

@mixin transition($second) {
  -webkit-transition: all $second ease-in-out;
    -moz-transition: all $second ease-in-out;
        transition: all $second ease-in-out;
}